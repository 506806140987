import React from "react";
import styled from "styled-components";
import {
  SwipeableDrawer,
  List,
  ListItem,
  IconButton,
  Divider,
} from "@mui/material";

// Material icons
import CloseIcon from "@mui/icons-material/Close";

// style
const MobileButtonLink = styled.a`
  width: 100%;
  border-radius: 0.2rem;
`;

const MobileButton = styled.button`
  /* color */
  color: var(--buttonHover);
  /* font */
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  display: flex;
  align-items: center;
  border: none;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  width: 100%;
  cursor: pointer;
`;

export default function SwipeableDrawerMobile({ drawer, setDrawer }) {
  // Drawer Handler for Tablet and Mobile device
  const drawerHandlerClose = (drawer) => {
    setDrawer(!drawer);
  };
  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onOpen={drawerHandlerClose}
      onClose={drawerHandlerClose}
    >
      <List sx={{ width: 280 }}>
        <ListItem sx={{ justifyContent: "flex-end" }}>
          <IconButton onClick={drawerHandlerClose}>
            <CloseIcon
              sx={{
                // borderRadius: "50%",
                // background: "var(--MainColor)",
                color: "var(--secondaryColor)",
                fontSize: "2rem",
                padding: ".2rem",
              }}
            />
          </IconButton>
        </ListItem>
        <Divider />
        {/* Home */}
        <ListItem>
          <MobileButtonLink href="/">
            <MobileButton onClick={drawerHandlerClose}>Home</MobileButton>
          </MobileButtonLink>
        </ListItem>
        <Divider />
        {/* Smart Home */}
        <ListItem>
          <MobileButtonLink href="https://zanoski.com/me" target="_blank">
            <MobileButton onClick={drawerHandlerClose}>Studio</MobileButton>
          </MobileButtonLink>
        </ListItem>
        <Divider />
        <ListItem>
          <MobileButtonLink
            href="https://run-skellybon-run.zanoski.com/privacy-policy.html"
            target="_blank"
          >
            <MobileButton onClick={drawerHandlerClose}>
              Privacy Policy
            </MobileButton>
          </MobileButtonLink>
        </ListItem>
        <Divider />
        <ListItem>
          <MobileButtonLink
            href="https://play.google.com/store/apps/details?id=com.zanoshky.runskellybonrun"
            target="_blank"
          >
            <MobileButton onClick={drawerHandlerClose}>Play Now</MobileButton>
          </MobileButtonLink>
        </ListItem>
        <Divider />
      </List>
    </SwipeableDrawer>
  );
}
