import React, { useEffect, useCallback } from "react";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

// json config
import jsonData from "../assets/json/star.json";

import "../App.css";

export default function SkyAnimation() {
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  return (
    <Particles
      className="particles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={jsonData}
    />
  );
}
