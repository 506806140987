import React, { useState } from "react";
import styled from "styled-components";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import Fade from "react-reveal/Fade";

import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";
import PolicyIcon from "@mui/icons-material/Policy";
import StorefrontIcon from "@mui/icons-material/Storefront";

// logo
import LogoImagePath from "../assets/images/navBar/logo.png";

// shared component
import SwipeableDrawerMobile from "./SwipeableDrawerMobile.js";

// styles
const MainContainer = styled.section`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;

  @media (max-width: 600px) {
    width: 100%;
    padding-block: 0.5rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 100%;
  }
`;

const Logo = styled.a``;

const LogoWrapper = styled.div`
  @media (max-width: 1024px) {
    flex-grow: 1;
  }
`;

const ImageWrapper = styled.div``;

const LogoImage = styled.img`
  width: 2rem;
  height: 100%;
  margin-top: 0.5rem;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 3rem;
    margin-top: 0;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    width: 2.5rem;
    margin-top: 0.3rem;
  }
`;

const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ButtonLink = styled.a`
  margin: 0 0.3rem;
  padding: 0.2rem 0;
  border-radius: 0.2rem;
`;

const ButtonTheme = styled.button`
  /* color */
  color: #fcfcfc;
  /* font */
  font-family: Noto Sans;
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
  letter-spacing: 0.5;
  padding: 0.6rem 0.4rem;
`;

const DesktopButton = styled(ButtonTheme)`
  background: transparent;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    text-shadow: 0 0 0px #fff, 0 0 2px #fff, 0 0 5px #3b9eff, 0 0 5px #3b9eff,
      0 0 10px #3b9eff;
    transition: all 0.1s ease-in-out;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

// Mobile Style
const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export default function NavBar() {
  const [drawer, setDrawer] = useState(false);
  const [linkActive, setLinkActive] = useState("home");

  return (
    <MainContainer>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          background: "transparent",
        }}
      >
        <Fade top delay={1000}>
          <Toolbar disableGutters={true}>
            <Wrapper>
              <LogoWrapper>
                <Logo href="/">
                  <ImageWrapper>
                    <div>
                      <LogoImage src={LogoImagePath} alt="Logo" />
                    </div>
                  </ImageWrapper>
                </Logo>
              </LogoWrapper>

              <DesktopWrapper>
                <ButtonLink href="/">
                  <DesktopButton>Home</DesktopButton>
                </ButtonLink>

                <ButtonLink href="https://zanoski.com/me" target="_blank">
                  <DesktopButton>Studio</DesktopButton>
                </ButtonLink>
              </DesktopWrapper>

              <CtaWrapper>
                <IconButton
                  href="https://run-skellybon-run.zanoski.com/privacy-policy.html"
                  target="_blank"
                  size="small"
                  sx={{
                    border: "2px transparent solid",
                    "&:hover": {
                      boxShadow:
                        "0 0 0px #fff, 0 0 5px #fff, 0 0 5px #3b9eff, 0 0 10px #3b9eff, 0 0 10px #3b9eff",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                >
                  <PolicyIcon
                    sx={{ color: "#fcfcfc", opacity: ".8", fontSize: "26px" }}
                  />
                </IconButton>
                <IconButton
                  href="https://play.google.com/store/apps/details?id=com.zanoshky.runskellybonrun"
                  size="small"
                  sx={{
                    border: "2px transparent solid",
                    "&:hover": {
                      boxShadow:
                        "0 0 0px #fff, 0 0 5px #fff, 0 0 5px #3b9eff, 0 0 10px #3b9eff, 0 0 10px #3b9eff",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                >
                  <StorefrontIcon
                    sx={{ color: "#fcfcfc", opacity: ".8", fontSize: "26px" }}
                  />
                </IconButton>
                <IconButton
                  href="https://play.google.com/store/apps/details?id=com.zanoshky.runskellybonrun"
                  size="small"
                  sx={{
                    border: "2px transparent solid",
                    "&:hover": {
                      boxShadow:
                        "0 0 0px #fff, 0 0 5px #fff, 0 0 5px #3b9eff, 0 0 10px #3b9eff, 0 0 10px #3b9eff",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{ color: "#fcfcfc", opacity: ".8", fontSize: "26px" }}
                  />
                </IconButton>
              </CtaWrapper>

              <MobileWrapper
                onClick={() => setDrawer(true)}
                style={{ cursor: "pointer" }}
              >
                <MenuIcon
                  sx={{ fontSize: "3rem", color: "var(--secondaryColor)" }}
                />
              </MobileWrapper>
            </Wrapper>
          </Toolbar>
        </Fade>
      </AppBar>
      <SwipeableDrawerMobile drawer={drawer} setDrawer={setDrawer} />
    </MainContainer>
  );
}
