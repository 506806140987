import React, { useState, useEffect, useRef, Suspense } from "react";
import Fade from "react-reveal/Fade";

import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Environment, OrbitControls } from "@react-three/drei";

import { useGLTF, useTexture, useAnimations } from "@react-three/drei";

import AnimationOne from "../assets/animation/TestPlayer_Anim_2.glb";

function Scene() {
  const myMesh = useRef();
  const group = useRef();
  const [index, setIndex] = useState(0);

  // const fbx = useLoader(FBXLoader, "/animation/Taunt.fbx");
  // const fbx = useLoader(FBXLoader, "/animation/TestPlayer.fbx");
  // const { nodes, materials, animations } = useGLTF(AnimationOne);

  // const { nodes, animations } = useGLTF("/animation/stacy.glb");
  // const texture = useTexture("/animation/stacy.jpg");

  // const { actions, names } = useAnimations(animations);

  const { nodes, materials, animations } = useGLTF(AnimationOne);

  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    console.log(animations);
  }, [actions]);

  return (
    // FOr FBX
    // <mesh ref={myMesh}>
    // 	<primitive object={fbx} scale={0.018} />
    // </mesh>

    // For GLB
    <mesh ref={myMesh}>
      <primitive object={nodes.Body} scale={0.02} />
    </mesh>
  );
}

export default function App() {
  return (
    <Suspense>
      <Fade right delay={1500}>
        <div style={{ width: "auto", height: "30vh" }}>
          <Canvas
            flat
            linear
            shadows
            camera={{ position: [4, 0, 30], fov: 10 }}
          >
            <OrbitControls />
            {/* <Environment preset='sunset' background /> */}
            <group position={[0, -1.5, 0]}>
              <Scene />
            </group>
            <ambientLight intensity={0.8} />
            <directionalLight />
          </Canvas>
        </div>
      </Fade>
    </Suspense>
  );
}
