import React, { useEffect } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Fade from "react-reveal/Fade";

// // shared
import Navbar from "./components/NavBar";

// // animation
import Animation from "./components/Animation";
import SkyAnimation from "./components/SkyAnimation";

// images
import BackgroundImagePath from "./assets/images/hero/bgImage.png";
import Logo1 from "./assets/images/hero/logo-1.png";
// import Logo2 from "./assets/images/hero/logo-2.png";
// import Logo3 from "./assets/images/hero/logo-3.png";
// import Logo4 from "./assets/images/hero/logo-4.png";

// css
import "./App.css";

// styles
const Container = styled.section`
  width: 100vw;
  height: 100vh;
  /* color */
  background: #6b16e7;
  /* display */
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  /* position */
  position: relative;
  z-index: 0;

  @media (max-width: 600px) {
    height: 100%;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    height: 100vh;
    align-items: flex-start;
  }
`;

const BgImage = styled.img`
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.3);
  pointer-events: none;

  border: 1px solid white;
`;

const Wrapper = styled.div`
  /* width */
  max-width: 1024px;
  /* position */
  z-index: 2;
  /* attr */
  margin: 0 auto;
  border-radius: 2rem;
  padding: 0 2rem 2rem 2rem;

  @media (max-width: 600px) {
    width: 95%;
    padding: 0;
  }
`;

const TextWrapper = styled.div`
  margin-top: 4rem;

  @media (max-width: 600px) {
    margin-top: 4rem;
    text-align: center;
  }
`;

const DescriptionWrapper = styled.div`
  /* margin */
  margin-top: 4rem;
`;

const DescriptionText = styled.p`
  margin-top: 2rem;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.75px;
  line-height: 1.8;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.65rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* margin */
  margin-top: 3rem;

  @media (min-width: 601px) and (max-width: 1024px) {
    margin-top: 2rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const CTAButtonThemeContained = styled.a`
  /* color */
  color: #0a0a0a;
  background-color: #fcfcfc;
  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-family: Noto Sans;
  letter-spacing: 1px;
  cursor: pointer;
  /* padding & attributes */
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 0.5rem;

  &:hover {
    scale: 1.1;
    transition: all 0.25s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 1.3rem 0;
    width: 100%;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 0.6rem 2.5rem;
  }
`;

const CTAButtonThemeOutlined = styled.button`
  /* color */
  color: #fcfcfc;
  background-color: transparent;
  /* font */
  font-size: 0.9rem;
  font-weight: 600;
  font-family: Noto Sans;
  letter-spacing: 1px;
  cursor: pointer;
  /* padding & attributes */
  padding: 0.95rem 2.5rem;
  border: 1px solid #fcfcfc;
  border-radius: 0.5rem;

  &:hover {
    scale: 1.04;
    transition: all 0.25s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 1.3rem 0;
    width: 100%;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 0.6rem 2.5rem;
  }
`;

const AvailableContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    margin-top: 4rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    margin-top: 2rem;
  }
`;

const AvailableWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const AvailableBox = styled.div``;

const AvailableText = styled.p`
  color: #fcfcfc;
  /* font */
  font-size: 0.9rem;
  /* attr */
  margin-bottom: 0.5rem;

  @media (max-width: 600px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const AvailableImage = styled.img`
  width: 9rem;
  height: 100%;
  object-fit: cover;

  @media (min-width: 601px) and (max-width: 1024px) {
    width: 4rem;
  }
  @media (max-width: 600px) {
    width: 9rem;
  }
`;

const ImageWrapper = styled.div``;

const ImageContent = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

function App() {
  const availableData = [
    {
      name: "Play Store",
      image: Logo1,
      link: "https://play.google.com/store/apps/details?id=com.zanoshky.runskellybonrun",
    },
    // { name: "Steam", image: Logo2 },
    // { name: "Steam", image: Logo3 },
    // { name: "Steam", image: Logo4 },
  ];

  return (
    <Container>
      <BgImage src={BackgroundImagePath} alt="Hero Image" />
      <Wrapper>
        {/* particle animations */}
        <SkyAnimation />
        {/* NavBar */}
        <Navbar />
        {/* Background Image */}

        {/* Background animation */}

        {/* Main Content */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row-reverse"
        >
          <Grid item xs={12} sm={6} md={6}>
            <ImageWrapper>
              {/* Animation Section */}
              <Animation />
            </ImageWrapper>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Fade bottom delay={2000}>
              <TextWrapper>
                <h1 className="headerMain">
                  <span className="headerText">LET</span>
                  &nbsp;&nbsp;
                  <span className="headerText">THE</span>
                  &nbsp;&nbsp;
                  <span className="headerText">GAME</span>
                  &nbsp;&nbsp;
                  <span className="headerText">BEGIN</span>
                </h1>
                <DescriptionWrapper>
                  <DescriptionText>
                    The brave Mr. Skellybon needs your help! They are afraid to
                    miss the next Spooktober. So, Mr. Skellybon set out on his
                    quest to collect as as much as candy as possible!
                  </DescriptionText>
                  <DescriptionText>
                    Come join Mr. Skellybon's&nbsp;
                    <span className="freeText">free</span>
                    <span className="freeText">-</span>
                    <span className="freeText">to</span>
                    <span className="freeText">-</span>
                    <span className="freeText">play</span> adventure and help
                    them feast on delicious candies! Mr. Skellybon needs&nbsp;
                    your &nbsp; help navigating the dark, mysterious, and creepy
                    dungeon.
                  </DescriptionText>
                </DescriptionWrapper>
                <ButtonWrapper>
                  <CTAButtonThemeContained href="https://play.google.com/store/apps/details?id=com.zanoshky.runskellybonrun">
                    Play Now
                  </CTAButtonThemeContained>
                  {/* <CTAButtonThemeOutlined>Play</CTAButtonThemeOutlined> */}
                </ButtonWrapper>

                <AvailableContainer>
                  <AvailableText>Available on:</AvailableText>
                  <AvailableWrapper>
                    {availableData.map((item, index) => (
                      <AvailableBox key={index}>
                        <a href={item.link}>
                          <AvailableImage
                            src={item.image}
                            alt={`Available Image on ${item.name}`}
                          />
                        </a>
                      </AvailableBox>
                    ))}
                  </AvailableWrapper>
                </AvailableContainer>
              </TextWrapper>
            </Fade>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
}

export default App;
